<template>
  <div class="top-content">
    <div class="top-form">
      <div class="left">
        <el-date-picker
          @change="handleDateChange"
          v-model="ruleForm.date"
          type="daterange"
          range-separator="到"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
        />
        <div class="time-type">
          <template v-for="(item, index) in timeType">
            <div
              @click="handleTimeClick(index)"
              :class="{ activeTime: currentIndex == index }"
              class="timeitem"
            >
              {{ item.title }}
            </div>
          </template>
        </div>
      </div>

      <div class="right">
        <el-select
          @change="handleShopChange"
          v-model="ruleForm.shopId"
          class="m-2"
          placeholder="选择门店"
          size="large"
        >
          <el-option
            v-for="item in shopList"
            :key="item.shopId"
            :label="item.shopName"
            :value="item.shopId"
          />
        </el-select>

        <el-select
          @clear="handleClear"
          clearable
          @change="handleTimeTypeChange"
          v-model="ruleForm.source"
          class="m-2"
          placeholder="订单来源"
          size="large"
        >
          <el-option
            v-for="item in sourceCategory"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <div>
          <el-input
            @clear="handleClear"
            clearable
            v-model="ruleForm.keyword"
            class="search"
            prefix-icon="search"
            placeholder="请输入手机号、退款编号"
          />
        </div>

        <div class="search-wrap">
          <el-button class="add-staff" type="primary" @click="handleSearchClick"
            >筛选</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch } from 'vue'
import { getShopList } from '@/service/main/order'
import { COMPANY_ID } from '@/common/constant'
import cache from '@/utils/cache'
import { timeType } from '../../config'
import dayjs from 'dayjs'
import emitter from '@/utils/eventbus'
import { useStore } from 'vuex'
import { sourceCategory } from '../../config'

export default defineComponent({
  props: {
    queryInfo: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {

  },
  setup(props) {
    const store = useStore()
    const timeType = [
      {
        title: '今天',
        id: 1
      },
      {
        title: '近三天',
        id: 3
      },
      {
        title: '近七天',
        id: 7
      }
    ]

    const ruleForm = reactive({
      date: "",
      shopId: "0",
      keyword: "",
      starttime: '',
      endtime: "",
      source: '1',
      page: 1,
      pagesize: 10
    })

    watch(() => props.queryInfo, (n) => {
      ruleForm.page = n.page
      ruleForm.pagesize = n.pagesize
      // console.log(n);
    }, {
      deep: true
    })


    const handleDateChange = value => {
       currentIndex.value = -1
      if (value == null) {
        ruleForm.starttime = ''
        ruleForm.endtime = ''
      } else {
        let d1 = dayjs(value[0]).format('YYYY-MM-DD')
        let d2 = dayjs(value[1]).format('YYYY-MM-DD')
        ruleForm.starttime = d1 + ' 00:00:00'
        ruleForm.endtime = d2 + ' 23:59:59'
      }
      getList()
    }


    const shopList = ref([])
    const handleShopChange = shopId => {

    }

    const emitsfunc = () => {

    }

    const disabledDate = (time) => {
      return time.getTime() > ruleForm.startTime.getTime()
    }

    const handleTimeTypeChange = value => {

    }

    const currentIndex = ref(-1)
    const handleTimeClick = index => {
      let date = dayjs().format('YYYY-MM-DD')
      let starttime, endtime
      starttime = date + ' 00:00:00'

      if (index == 0) {
        endtime = date + ' 23:59:59'
      } else if (index == 1) {
        let currentDate = dayjs().add(3, 'day').format('YYYY-MM-DD')
        endtime = currentDate + ' 23:59:59'
      } else if (index == 2) {
        let currentDate = dayjs().add(7, 'day').format('YYYY-MM-DD')
        endtime = currentDate + ' 23:59:59'
      }
      currentIndex.value = index
      ruleForm.starttime = starttime
      ruleForm.endtime = endtime
      getList()
    }

    const handleClear=()=>{
       getList()
    }

    const handleSearchClick = () => {
      getList()
    }

    const getList = () => {
      store.dispatch('order/getRefoundListAction', { ...ruleForm })
    }

    const initPage = async () => {
      const res = await getShopList(COMPANY_ID)
      let list = res.data.list
      list.unshift({
        shopName: "总部",
        shopId: "0"
      })
      shopList.value = res.data.list
      getList()
    }
    initPage()


    return {
      ruleForm,
      shopList,
      handleShopChange,
      timeType,
      handleDateChange,
      handleTimeTypeChange,
      emitsfunc,
      disabledDate,
      timeType,
      currentIndex,
      handleTimeClick,
      sourceCategory,
      handleSearchClick,
      handleClear
    }

  }
})
</script>

<style scoped lang="less">
.top-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #fff;
  margin-bottom: 20px;

  .time-type {
    display: flex;
    align-items: center;

    .activeTime {
      color: var(--el-color-primary);
      border-color: var(--el-color-primary) !important;
    }

    .timeitem {
      line-height: 40px;
      height: 40px;
      padding: 0 20px;
      font-size: 13px;
      border: 1px solid rgb(220, 223, 230);
      margin-left: 10px;
      cursor: pointer;
    }
  }

  &:deep(.el-input) {
    margin-right: 10px;
  }

  .bottom-form {
    display: flex;
    align-items: center;

    .down-record {
      margin-left: 10px;
      font-size: 13px;
      color: rgb(64, 158, 255);
      cursor: pointer;
    }
  }

  .top-form {
    .left,
    .right {
      display: flex;
    }

    .right {
      margin-top: 14px;

      &:deep(.el-select) {
        margin-right: 10px;
      }
    }

    .search-wrap {
      margin-left: 10px;
    }

    .download {
      margin-right: 10px;
    }
  }
}
</style>
