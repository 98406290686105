export const breadcrumbList = [
  {
    name: '订单管理',
    path: ''
  },
  {
    name: '退款业绩',
    path: ''
  }
]


export const contentTableConfig = {
  propList: [
    {
      prop: 'refundNo',
      label: '退款单号',
      minWidth: '140',
      slotName: 'refundNo'
    },
    { prop: 'orderNo', label: '订单编号', minWidth: '200' , slotName: 'orderNo'},
    {
      prop: 'suject',
      label: '商品名称',
      minWidth: '200',
      slotName: 'suject'
    },
    {
      prop: 'orderKind',
      label: '订单类型',
      minWidth: '120',
      slotName: 'orderKind'
    },
    {
      prop: 'amount',
      label: '退款金额',
      minWidth: '120',
      slotName: 'amount'
    },
    {
      prop: 'shopName',
      label: '退款门店',
      minWidth: '120',
      slotName: 'shopName'
    },
    {
      prop: 'createdAt',
      label: '退款日期',
      minWidth: '120',
      slotName: 'createdAt'
    },
    {
      prop: 'userRealname',
      label: '客户',
      minWidth: '120',
      slotName: 'userRealname'
    },
    {
      prop: 'orderSource',
      label: '订单来源',
      minWidth: '120',
      slotName: 'orderSource'
    },
    // {
    //   prop: 'isBuy',
    //   label: '状态',
    //   minWidth: '120',
    //   slotName: 'is_buy'
    // },
    { label: '操作', minWidth: '200', slotName: 'handler', }
  ],
  showIndexColumn: false,
  showSelectColumn: true
}

export const timeType=[
  {
    label:'日报',
    value:0,
  }, {
    label:'月报',
    value:1,
  }
]

export const sourceCategory = [
  {
    label: '小程序',
    value:'1'
  },
  {
    label: '美团',
    value:'2'
  },{
    label: '饿了么',
    value:'3'
  },
  {
    label: '有赞',
    value:'4'
  }
]
