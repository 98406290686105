<template>
  <div class="refund-list">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="main-content">
      <topContent :queryInfo="queryInfo"></topContent>
      <page-content
        :dataList="refoundList"
        @selectionChange="selectionChange"
        :contentTableConfig="contentTableConfig"
        :isButtonStyle="true"
        @tableCellClick="handleTableCellClick"
      >
        <template #handler="scope">
          <el-button
            @click="handleRefundDetail(scope.row)"
            size="small"
            plain
          >详情</el-button>
        </template>

        <template #amount="scope">
          ¥ {{(scope.row.amount/100).toFixed(2)}}
        </template>
        <template #orderSource="scope">
          <template v-if="scope.row.orderSource==1">
            小程序
          </template>
          <template v-if="scope.row.orderSource==2">
            美团
          </template>
          <template v-if="scope.row.orderSource==3">
            饿了么
          </template>
          <template v-if="scope.row.orderSource==4">
            有赞
          </template>
        </template>
        <template #orderKind="scope">
          <template v-if="scope.row.orderNo.indexOf('SRV')!=-1">
            预约
          </template>
          <template v-if="scope.row.orderNo.indexOf('CDY')!=-1">
            有限次卡
          </template>
          <template v-if="scope.row.orderNo.indexOf('CDW')!=-1">
            不限次卡
          </template>
          <template v-if="scope.row.orderNo.indexOf('CDM')!=-1">
            储值卡
          </template>
          <template v-if="scope.row.orderNo.indexOf('CSH')!=-1">
            余额充值
          </template>

        </template>
        <template #pagination>
          <el-pagination
            v-model:currentPage="queryInfo.page"
            v-model:page-size="queryInfo.pagesize"
            :page-sizes="[10, 20, 30, 40]"
            :small="small"
            layout="total, sizes, prev, pager, next, jumper"
            :total="refoundListCount"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </template>
      </page-content>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed } from 'vue'
import breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { contentTableConfig } from './config'
import topContent from './cpns/top-content/top-content.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default defineComponent({
  props: {

  },
  components: {
    breadcrumb,
    PageContent,
    topContent
  },
  setup() {
    const router=useRouter()
    const store = useStore()

    const queryInfo = reactive({
      page: 1,
      pagesize: 10
    })

    const initPage = () => {

    }
    initPage()

    const handleRefundDetail=item=>{
      router.push({
        path:'/main/order/orderRefundDetail',
        query:{
          orderNo:item.orderNo
        }
      })
    }


    const dataCount = ref(0)
    const refoundList = computed(() => store.state.order.refoundList)
    const refoundListCount = computed(() => store.state.order.refoundListCount)

    return {
      breadcrumbList,
      contentTableConfig,
      queryInfo,
      dataCount,
      refoundList,
      refoundListCount,
      handleRefundDetail
    }
  }
})
</script>

<style scoped lang="less">
.refund-list {
  .main-content {
    padding: 20px;
    margin: 20px;
    background-color: #fff;
  }
}
</style>




